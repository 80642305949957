/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('header .visible-lg .menu-item-depth-1').wrapAll('<div class="container"></div>');
        // Twitter block 
        var config = {
          "id": '645969392640106496',
          "domId": 'tweets',
          "maxTweets": 3,
          "enableLinks": true,
          "showUser": true,
          "showImages": true,
          "showRetweet": false,
          "showTime": true
        };
        if( $('#tweets').length > 0){
          twitterFetcher.fetch(config);
        }
        
        //Youtube block
        function getVids(pid){
            $.get(
                "https://www.googleapis.com/youtube/v3/search?order=date&part=snippet&channelId=UC9eu2-x3JkSei5Yx7eq1WGg&key=AIzaSyA0dJT0m9NDzmJK5hC8aT6VuJ8QmeTtbZs",{
                maxResults : 3
              },
              function(data) {
                  var results;
                  $.each( data.items, function( i, item ) {
                      results = '<li class="clearfix"><a target="_blank" class="clearfix" href="https://www.youtube.com/watch?v='+ item.id.videoId +'" title="'+ item.snippet.title +'"><img src="'+ item.snippet.thumbnails['default'].url +'" alt="'+ item.snippet.title +'" width="120" height="90" /><span class="title_vid">'+ item.snippet.title +'</span></a></li>';
                      $('#youtube_results').append(results);
                  });
              }
            );
        }
        if( $('#youtube_results').length > 0){
          getVids();
        }

        if( $('.flexible-splide').length > 0){
          new Splide( '.flexible-splide', {
            arrows: false,
          }).mount();
        }
        

        //Sub-menu
        $('#menu-menu-principal > .menu-item-has-children > a').on('click',function(event){
          if( $(this).next().length > 0 ){
            event.preventDefault();
            $(this).next().slideToggle('fast');
          }
        });
        //Hide submenu when click outside it
        $(document).click(function (e){
          var submenu = $("#menu-menu-principal > .menu-item-has-children > .sub-menu");
          var parentItem = $('#menu-menu-principal > .menu-item-has-children > .sub-menu').parents('li');
          if( !parentItem.is(e.target) && parentItem.has(e.target).length === 0 ){
            if (!submenu.is(e.target) && submenu.has(e.target).length === 0){
              submenu.slideUp();
            }
          }
        });

        // VERSION EN

        //Sub-menu
        $('#menu-menu-principal-en > .menu-item-has-children > a').on('click',function(event){
          if( $(this).next().length > 0 ){
            event.preventDefault();
            $(this).next().slideToggle('fast');
          }
        });
        //Hide submenu when click outside it
        $(document).click(function (e){
          var submenu = $("#menu-menu-principal-en > .menu-item-has-children > .sub-menu");
          var parentItem = $('#menu-menu-principal-en > .menu-item-has-children > .sub-menu').parents('li');
          if( !parentItem.is(e.target) && parentItem.has(e.target).length === 0 ){
            if (!submenu.is(e.target) && submenu.has(e.target).length === 0){
              submenu.slideUp();
            }
          }
        });

        if( $('.flexible-content').length > 0 && $('.intro-page').length > 0 ){
          $('.intro-page').css('marginBottom',0);
        }

        //Flexible nav
        if($('.flexible-nav').length > 0){
          var tabId = Array();
          $('.flexible-bloc').each(function(){
            tabId = $(this).index();
            var title = $(this).find('.flexible-title').text();
            $(this).attr('id','flexible-bloc-'+tabId);
            $('<a href="#flexible-bloc-'+tabId+'" data-scroll="flexible-bloc-'+tabId+'"><span class="title">'+title+'</span></a>').appendTo('.flexible-nav');
          });
          $('.flexible-nav a').on('click',function(e){
            e.preventDefault();
            var href = $(this).attr('href');
            var scrollTo = $(href).offset().top;
            $('html, body').animate({ scrollTop: $(href).offset().top }, 'normal');
            $('.flexible-nav a').removeClass('selected');
            $(this).addClass('selected');
          });

        }

        //Search
        $('.search-btn').on('click',function(e){
          e.preventDefault();
          $('header .search-form').fadeIn();
          $('#ajaxsearchlite1 input[type=search]').focus();
        });
        $('.closeoverlay, .overlay').on('click',function(e){
          $('.search-form').fadeOut();
        });

        //Video vertical alignment
        var container_height = $('.video-container').height();
        var video_height = $('.video-container iframe').height();
        var diff = video_height-container_height;
        $('.video-container iframe').css('marginTop',-diff);
        $(document).on('click','.menu-mobile .btn-menu',function(){
            $('#mobile-menu').slideToggle();
            $(this).toggleClass('is-active');
            // if( $(this).hasClass('collapsed') ){
            //     $(this).find('.title').text('Menu');
            //     if( $('header.home').length > 0){
            //         $('header.home').css('overflow','hidden');
            //     }
            // }else{
            //     if( $('html[lang="en-US"]').length > 0){
            //         $(this).find('.title').text('Close');
            //     }else{
            //         $(this).find('.title').text('Fermer');
            //     }
            //     if( $('header.home').length > 0){
            //         setTimeout(function(){ $('header.home').css('overflow','visible'); },200);
            //     }
            // }
        });

        // TABS

        $('.onglets .nav-item ').on('click', function(e){
          var tabData = $(this).data('tab');
          console.log(tabData);
          $('.nav-tabs .nav-item').removeClass('active');
          $(this).addClass('active');

          $('.tab').removeClass('active');
          $('.tab[data-tab='+tabData+']').addClass('active');
          $('.button-tab-mobile').removeClass('active');
          $('.button-tab-mobile[data-tab='+tabData+']').addClass('active');
        })

        $('.button-tab-mobile').on('click', function(e){
          var tabData = $(this).data('tab');
          $(this).toggleClass('active');
          $('.content-tab .tab[data-tab='+tabData+']').slideToggle();
        })

        // ACCORDION

        $('.toggle').click(function(e) {
          e.preventDefault();
          var $this = $(this);
          if ($this.next().hasClass('shows')) {
              $this.next().removeClass('shows');
              $this.removeClass('active');
              $this.next().slideUp(350);
          } else {
              $this.parent().parent().find('li .tab-content').removeClass('shows');
              $this.parent().parent().find('li .tab-title').removeClass('active');
              $this.parent().parent().find('li .tab-content').slideUp(350);
              $this.toggleClass('active');
              $this.next().toggleClass('shows');
              $this.next().slideToggle(350);
          }
        });

        //SCROLL TO

        $('.smoothScroll').click(function() {
          if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $('html,body').animate({
                scrollTop: target.offset().top - 150
              }, 1000); // The number here represents the speed of the scroll in milliseconds
              return false;
            }
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        //Header resizing effect
        $(window).on('scroll',function(){
          var height = $(document).scrollTop();
          var width = $(window).width();
          if( width >= 960){
            if( height >= 300){
              $('.menu-container').addClass('smaller');
            }else{
              $('.menu-container').removeClass('smaller');
            }
          }
        });
      }
    },
    'tax_categories_produit': {
      init: function() {
        // var sm_height = $('.product_cat li.current .sub-menu').outerHeight();
        // var li_height = $('.product_cat li.current').height();
        // if( $(window).width() > 1024 ){
        //     $('section.product_cat').height(sm_height+li_height);
        // }
        // //Fancybox product images
        // $('.fancybox').fancybox();
        // //Calc overview width dynamically for tinyscrollbar
        // var li_width = $('li.subcategory').width();
        // var nb_li = $('.current .overview li.subcategory').length;
        // $('.overview').width(li_width*nb_li);
        // //Scrollbar init
        // var $catScrollbar = $("li.current #scrollbar2");
        // $catScrollbar.tinyscrollbar({ axis: "x", wheel: true});
        // var catScrollbar = $catScrollbar.data("plugin_tinyscrollbar");
        // if( $('.current .scrollbar').hasClass('disable') ){
        //   $('.current #scrollbar2 .navigation').hide();
        // }
        
        // //Nav buttons with tinyscrollbar
        // $('.current #scrollbar2 .navigation a').on('click',function(e){
        //   e.preventDefault();
        //   //Prev button
        //   if( $(this).hasClass('pull-left') ){
        //     if( catScrollbar.contentPosition >= li_width ){
        //       catScrollbar.update(catScrollbar.contentPosition-li_width);
        //     }else if( catScrollbar.contentPosition > 0 && catScrollbar.contentPosition < li_width){
        //       catScrollbar.update(0);
        //     }
        //   }
        //   //Next button
        //   if( $(this).hasClass('pull-right') ){
        //     if( catScrollbar.contentSize-catScrollbar.viewportSize >= catScrollbar.contentPosition+li_width ){
        //       catScrollbar.update(catScrollbar.contentPosition+li_width);
        //     }else if( catScrollbar.contentSize-catScrollbar.viewportSize > catScrollbar.contentPosition-li_width && catScrollbar.contentPosition < catScrollbar.contentSize-catScrollbar.viewportSize){
        //       catScrollbar.update(catScrollbar.contentSize-catScrollbar.viewportSize);
        //     }
        //   }
        // });

        // //Auto scroll when current cat is outside scrollbar viewport
        // if( $('.current .subcategory.active').length > 0 ){
        //   var index = $('.current .subcategory.active').index();
        //   index++;
        //   if( index > 7 ){
        //     catScrollbar.update( 15+(index*li_width)-catScrollbar.viewportSize );
        //   }
        // }
      },
      finalize : function(){
      }
    },
    'page_template_template_contact': {
      init: function() {
        //Contact page maps
        // var map1, map2;
        // function initMap1() {
        //   var lat = $('#map1').data('lat');
        //   var lng = $('#map1').data('lng');
        //   map1 = new google.maps.Map(document.getElementById('map1'), {
        //     center: {lat: lat, lng: lng},
        //     zoom: 8,
        //     disableDefaultUI: true,
        //     scrollwheel: false
        //   });
        //   marker1 = new google.maps.Marker({
        //     position: new google.maps.LatLng(lat, lng),
        //     map: map1,
        //     icon : $('#map1').data('pin')
        //   });
        // }
        // function initMap2() {
        //   var lat = $('#map2').data('lat');
        //   var lng = $('#map2').data('lng');
        //   map2 = new google.maps.Map(document.getElementById('map2'), {
        //     center: {lat: lat, lng: lng},
        //     zoom: 8,
        //     disableDefaultUI: true,
        //     scrollwheel: false
        //   });
        //   marker2 = new google.maps.Marker({
        //     position: new google.maps.LatLng(lat, lng),
        //     map: map2,
        //     icon : $('#map2').data('pin')
        //   });
        // }
        // initMap1();
        // initMap2();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Home page
    'home': {
      init: function() {
        //Home slider
        new Splide( '.splide', {
          arrows: false,
        }).mount();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
